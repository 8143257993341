<template>
  <div class="template">
    <!-- 标题 -->
    <!-- 创建新服务报表模版 lable.Create.new.template-->
    <div class="title">{{ $t("lable.Create.new.template")}}</div>
    <!-- 配置新模板 -->
    <div class="list">
      <!-- 标题 -->
      <!-- 选择一个已有的服务报表模版作为您新建模版的基础。 lable.Select.a.template-->
      <div class="title secTitle">
        {{ $t("lable.Select.a.template")}}
      </div>
      <!-- 主体 -->
      <div class="body">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <!-- 第一行 -->
          <div class="firColumn">
            <div style="color: red">*</div>
            <!-- 现有模板 lable.Existing.template-->
            <div class="templateTitle">{{ $t("lable.Existing.template")}}</div>
            <div class="Existing-template">
              <el-form-item prop="templateId">
                <el-select v-model="ruleForm.templateId" placeholder="">
                  <el-option
                    v-for="item in templateList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <!-- 第二行 -->
          <!-- 模板名称 label.emailobject.template.name-->
          <div class="firColumn secColumn">
            <div style="color: red">*</div>
            <div class="templateTitle">{{ $t("label.emailobject.template.name") }}</div>
            <div class="template-name">
              <el-form-item prop="newName">
                <el-input v-model="ruleForm.newName" placeholder=""></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <!-- 按钮 -->
      <div class="btnGroup">
        <!--保存 component.telerecord.button.save-->
        <!-- 取消 label.emailsync.button.cancel-->
        <el-button type="primary" @click="submitForm('ruleForm')">{{ $t("component.telerecord.button.save") }}</el-button>
        <el-button @click="goBack">{{ $t("label.emailsync.button.cancel") }}</el-button>
      </div>
    </div>
  </div>
</template>




<script>
import * as ReportTemplate from "./api.js";
// import { json } from "gantt";
export default {
  data() {
    return {
      ruleForm: {
        templateId: "",
        newName: "",
      },
      rules: {
        //请输入模板名称 lable.Enter.template.name
        //模版名称最长允许50个字符 lable.Maximum.number
        newName: [
          { required: true, message: this.$i18n.t("lable.Enter.template.name"), trigger: "blur" },
          { max: 50, message: this.$i18n.t("lable.Maximum.number"), trigger: 'blur' }
        ],
        //请选择现有模板 lable.Please.select.a.template
        templateId: [
          { required: true, message: this.$i18n.t("lable.Please.select.a.template"), trigger: "change" },
        ],
      },
      templateList: [],
    };
  },
  methods: {
    //表单验证
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.createNewTemplste()
          } else {
            return false;
          }
        });
      },
    //新建服务报表模板
    
    async createNewTemplste() {
      let resultData = await ReportTemplate.createTemplate({
        id: this.ruleForm.templateId,
        name: this.ruleForm.newName,
      });
      if (resultData.result) {
        this.$router.push({
          path: "editTemplate",
          query: {
            Tid: resultData.data,
            Tname: this.ruleForm.newName,
          },
        });
      }
    },
    
    choseObj(index) {
      this.name = this.templateList[index].name;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.templateList = JSON.parse(this.$route.query.querytemplateList);
  },
  created() {},
  components: {},
};
</script>


<style lang="scss" scoped>
* {
  margin: 0;
}
.el-form-item {
    margin-bottom: 0;
}
.template {
  padding-top: 18px;
  padding-left: 10px;
}
.title {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 26px;
}
.list {
  margin-top: 20px;
  background: #f5f5f5;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
}
.secTitle {
  padding: 10px;
  font-size: 16px;
}
.body {
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 92px;
}
.btnGroup {
  display: flex;
  justify-content: center;
  height: 50px;
  padding: 13px 0px;
}
::v-deep .el-button {
  line-height: 0px;
}
.firColumn {
  display: flex;
  align-items: center;
}
.secColumn {
  margin-top: 20px;
}
.templateTitle {
  margin-right: 20px;
}
.Existing-template {
  ::v-deep .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  ::v-deep .el-input__icon {
    line-height: 30px;
  }
  ::v-deep .created-type {
    width: 120px;
    margin-right: 6px;
  }
}
.template-name {
  ::v-deep .el-input__inner {
    height: 30px;
    line-height: 30px;
    padding-right: 12px;
  }
}
input {
  border: none;
  outline: none;
  min-width: 120px;
  padding: 3px 5px 3px 10px;
}
.inputStyle {
  display: relative;
}
.inputStyle i {
  position: absolute;
  right: 8px;
  top: 6px;
}
::v-deep .singleObj {
  padding: 7px 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.objTitle {
  cursor: pointer;
  font-size: 12px;
}
.divStyle:hover {
  background: #f5f5f5 !important;
  color: #006dcc !important;
}
</style>
